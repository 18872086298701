import { GreyGooseTheme } from './schemes/GreyGooseTheme';
import { NebulaFighterTheme } from './schemes/NebulaFighterTheme';

const themeMap = {
	GreyGooseTheme,
	NebulaFighterTheme,
};

export function themeCreator(theme) {
	return themeMap[theme];
}
