import { useEffect, useState } from 'react';

const useScript = (scriptID, url, callback) => {
    const [ready, setReady] = useState(false);

	useEffect(() => {
		const existingScript = document.getElementById(scriptID);
		if (!existingScript) {
			const script = document.createElement('script');
			script.src = url;
			script.id = scriptID;
            script.async = true;
			document.body.appendChild(script);

			script.onload = () => {
				if (callback) {
					callback();
				}
			};
		}

        setReady(true);

		if (existingScript && callback) {
			callback();
		}

		return () => {
			if (existingScript && callback) {
				existingScript.remove();
			}
		};
	}, [url, callback, scriptID, setReady]);

    return ready;
};

export default useScript;
