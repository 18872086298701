import { fork, put, takeLatest } from 'redux-saga/effects';
import { authActions } from '../actions/auth';
import { initActions } from '../actions/init';

function* makeInitialize() {
	yield put(authActions.isLoggedInAction());
}

function* initialize() {
	yield takeLatest(initActions.initializeAction.type, makeInitialize);
}

function* rootMain() {
	yield fork(initialize);
}

export { rootMain };
