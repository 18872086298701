import { createReducer } from '@reduxjs/toolkit';
import { operationsActions } from '../actions/operations';

const operationsState = {
	manager: {
		// mock
		// country: 'IL',
		// phone: {
		// 	country_phone_code: '+972',
		// 	country_code: 'IL',
		// 	country_name: 'Israel',
		// 	phone: '123456789',
		// },
	},
	affRegulations: null,
	links: [],
	macros: [],
	params: [],
	postbacks: [],
	events: [],
	commissions: [],
	costTypes: [],
	isFormSent: false,
	error: null,
	paymentDetails: null,
};

const operationsReducer = createReducer(operationsState, (builder) => {
	builder
		.addCase(operationsActions.setErrorAction, (state) => {
			state.error = true;
		})
		.addCase(
			operationsActions.getCostTypesSuccessAction,
			(state, { payload }) => {
				state.costTypes = payload;
			}
		)
		.addCase(
			operationsActions.getTrackingLinksSuccessAction,
			(state, { payload }) => {
				state.links = payload || [];
			}
		)
		.addCase(
			operationsActions.getMacrosSuccessAction,
			(state, { payload }) => {
				state.macros = payload || [];
			}
		)
		.addCase(
			operationsActions.getParamsSuccessAction,
			(state, { payload }) => {
				state.params =
					payload?.filter((param) => param?.name?.trim()) || [];
			}
		)
		.addCase(
			operationsActions.getCompliancePageSuccessAction,
			(state, { payload }) => {
				const regulationMap = new Map();

				payload.forEach((el) => {
					if (!regulationMap.has(el.regulation_id)) {
						regulationMap.set(el.regulation_id, []);
					}
					regulationMap.get(el.regulation_id).push(el);
				});

				state.affRegulations = regulationMap;
			}
		)
		.addCase(
			operationsActions.getCommissionPlansSuccessAction,
			(state, { payload }) => {
				state.commissions = payload;
			}
		)
		.addCase(
			operationsActions.createBenefeciarySuccessAction,
			(state) => {
				state.paymentDetails = {
					tokenization_page_id: 'hp_ben_1d82d0cb9bda93f02014eaf3f1f82503',
					identification_page_id: '123456',
				};
			}
		)
		.addCase(
			operationsActions.getManagerDataSuccessAction,
			(state, { payload }) => {
				state.manager = payload;
			}
		)
		.addCase(
			operationsActions.setFormSentStatusAction,
			(state, { payload }) => {
				state.isFormSent = payload;
			}
		)
		.addCase(
			operationsActions.createPostbackSuccessAction,
			(state, { payload }) => {
				state.postbacks = [...state.postbacks, payload];
			}
		)
		.addCase(
			operationsActions.getEventsSuccessAction,
			(state, { payload }) => {
				state.events = payload;
			}
		)
		.addCase(
			operationsActions.getPostbacksSuccessAction,
			(state, { payload }) => {
				state.postbacks = payload;
			}
		)
		.addCase(
			operationsActions.updatePostbackSuccessAction,
			(state, { payload }) => {

				const selectedIndex = state.postbacks.findIndex(
					(el) => +el.id === +payload.id
				);
				state.postbacks = [
					...state.postbacks.slice(0, selectedIndex),
					payload,
					...state.postbacks.slice(selectedIndex + 1),
				];
				state.error = null;
			}
		)
		.addCase(operationsActions.clearOperationsDataAction, () => operationsState)
		// .addCase(
		// 	operationsActions.getCommissionsSuccessAction,
		// 	(state, { payload }) => {
		// 		state.commissions = payload;
		// 	}
		// )
		// .addCase(
		// 	operationsActions.signDocumentAction,
		// 	(state, { payload }) => {
		// 		console.log('payload: ', payload);
		// 		// state.commissions = payload;
		// 	}
		// )
		.addDefaultCase((state) => state);
});

export default operationsReducer;
