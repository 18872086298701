import { createAction } from '@reduxjs/toolkit';

export const dashboardActions = {
	getDashboardDataAction: createAction('dashboard/getDashboardData'),
	getDashboardDataSuccessAction: createAction('dashboard/getDashboardDataSuccess'),
	getWalletDataAction: createAction('dashboard/getWalletData'),
	getWalletDataSuccessAction: createAction('dashboard/getWalletDataSuccess'),
	getDashboardAnalyticAction: createAction('dashboard/getDashboardAnalytic'),
	getBrandsSuccessAction: createAction('dashboard/getBrandsSuccess'),
	getSourcesAction: createAction('dashboard/getSources'),
	getSourcesSuccessAction: createAction('dashboard/getSourcesSuccess'),
	getProductsSuccessAction: createAction('dashboard/getProductsSuccess'),
	getBrandAction: createAction('dashboard/getBrand'),
	getBalanceAnalyticSuccessAction: createAction('dashboard/getBalanceAnalyticSuccess'),
	setErrorAction: createAction('dashboard/setError'),
	clearDashboardDataAction: createAction('dashboard/clearDashboardData'),
};
