import { createReducer } from '@reduxjs/toolkit';
import { dashboardActions } from '../actions/dashboard';

const dashboardState = {
	balance: 0,
	unsettled_balance: 0,
	lifetime_earnings: 0,
	adv_earnings: [],
	balances_daily: {},
	affiliate_transactions: [],
	sources: [],
	stats: [],
	brands: [],
	products: [],
	error: null,
};

const dashboardReducer = createReducer(dashboardState, (builder) => {
	builder
		.addCase(dashboardActions.getDashboardDataSuccessAction, (state, { payload }) => {
			state.balance = payload?.balance;
			state.unsettled_balance = payload?.unsettled_balance;
			state.lifetime_earnings = payload?.lifetime_earnings;
			state.adv_earnings = payload?.adv_earnings;
		})
		.addCase(dashboardActions.getWalletDataSuccessAction, (state, { payload }) => {
			state.balance = payload?.balance;
			state.unsettled_balance = payload?.unsettled_balance;
			state.lifetime_earnings = payload?.lifetime_earnings;
			state.affiliate_transactions = payload?.affiliate_transaction;
		})
		.addCase(dashboardActions.getBrandsSuccessAction, (state, { payload }) => {
			state.brands = payload;
		})
		.addCase(dashboardActions.getBalanceAnalyticSuccessAction, (state, { payload }) => {
			state.balances_daily = payload;
		})
		.addCase(dashboardActions.getSourcesSuccessAction, (state, { payload }) => {
			state.sources = payload;
		})
		.addCase(dashboardActions.getProductsSuccessAction, (state, { payload }) => {
			state.products = payload;
		})
		.addCase(dashboardActions.setErrorAction, (state) => {
			state.error = true;
		})
		.addCase(dashboardActions.clearDashboardDataAction, () => dashboardState)
		.addDefaultCase((state) => state);
});

export default dashboardReducer;
