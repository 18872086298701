import React, { useContext, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { uiActions } from '../../../../../redux/actions/ui';
import { ThemeContext } from '../../../../../theme/MuiThemeProvider';
import { ThemeToggleWrapper } from '../../../styles';

const ThemeToggle = (_props) => {
	const dispatch = useDispatch();
	const { appTheme } = useSelector(({ ui }) => ui, shallowEqual);
	const setThemeName = useContext(ThemeContext);
	const curThemeName = appTheme || 'GreyGooseTheme';

	const [theme, setTheme] = useState(curThemeName);

	const changeTheme = (event) => {
		setTheme(event.target.value);
		setThemeName(event.target.value);
		dispatch(uiActions.setThemeAction(event.target.value));
		dispatch(uiActions.toggleSettingsAction(false));
	};

	return (
		<>
			<ThemeToggleWrapper>
				<FormControl fullWidth variant='outlined'>
					<InputLabel id='theme-selector'>Change Theme</InputLabel>
					<Select
						id='theme-selector'
						value={theme}
						onChange={changeTheme}
						label='Change Theme'
					>
						<MenuItem value={'GreyGooseTheme'}>Grey Goose</MenuItem>
						<Divider sx={{ my: 1 }} />
						<MenuItem value={'NebulaFighterTheme'}>
							Nebula Fighter
						</MenuItem>
					</Select>
				</FormControl>
			</ThemeToggleWrapper>
			<Divider />
		</>
	);
};

export default ThemeToggle;
