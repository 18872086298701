import React, { useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Badge, IconButton, Popover, Tooltip } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { uiActions } from '../../../../redux/actions/ui';
import Notifications from '../../../Notifications';
import { userActions } from '../../../../redux/actions/user';
import ThemeToggle from './ThemeToggle';

const HeaderSettings = () => {
	const dispatch = useDispatch();
	const settingsRef = useRef(null);
	const notificationsRef = useRef(null);
	const { settingsOpen, notificationsOpen } = useSelector(
		({ ui }) => ui,
		shallowEqual
	);
	const notifications = useSelector(
		({ user }) => user.notifications,
		shallowEqual
	);
	const xSID = useSelector(({ auth }) => auth.xSID, shallowEqual);

	const openSettings = () => {
		dispatch(uiActions.toggleSettingsAction(true));
	};
	const openNotifications = () => {
		notifications?.length
			? dispatch(uiActions.toggleNotificationsAction(true))
			: dispatch(userActions.getNotificationsPreferencesAction({ xSID }));
	};
	const closeSettings = () => {
		dispatch(uiActions.toggleSettingsAction(false));
	};
	const closeNotifications = () => {
		dispatch(uiActions.toggleNotificationsAction(false));
	};

	return (
		<>
			<Tooltip arrow title='Notifications'>
				<IconButton
					color='primary'
					ref={notificationsRef}
					onClick={openNotifications}
					sx={{ mr: 1 }}
				>
					<Badge badgeContent={0} color='error'>
						<NotificationsActiveTwoToneIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={notificationsRef.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={closeNotifications}
				open={notificationsOpen}
				sx={{ '.MuiPaper-root': { width: 'max-content' } }}
			>
				<Notifications />
			</Popover>
			<Tooltip arrow title='Settings'>
				<IconButton
					color='primary'
					ref={settingsRef}
					onClick={openSettings}
					sx={{ mr: 1 }}
				>
					<SettingsTwoToneIcon />
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={settingsRef.current}
				onClose={closeSettings}
				open={settingsOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<ThemeToggle />
			</Popover>
		</>
	);
};

export default HeaderSettings;
