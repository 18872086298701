import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { IOSSwitch } from '../../../components/Notifications/styles';

const NotificationSetting = ({ el, onChange }) => {
	return (
		<TableRow>
			<TableCell
				sx={{
					p: 1.5,
					width: 'calc(100% - 100px)',
					borderBottom: 'none',
				}}
			>
				<Typography variant='h6'>{el?.name}</Typography>
			</TableCell>
			<TableCell sx={{ p: 1, borderBottom: 'none' }}>
				<IOSSwitch
					checked={!!el?.is_active}
					onChange={() => onChange(el)}
				/>
			</TableCell>
		</TableRow>
	);
};

export default NotificationSetting;
