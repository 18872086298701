import { createAction } from '@reduxjs/toolkit';

export const walletActions = {
	getBeneficiaryAction: createAction('wallet/getBeneficiary'),
	getWalletSettingsAction: createAction('wallet/getWalletSettings'),
	getWalletSettingsSuccessAction: createAction('wallet/getWalletSettingsSuccess'),
	updateWalletSettingAction: createAction('wallet/updateWalletSetting'),
	getWalletBrandsSuccessAction: createAction('wallet/getWalletBrandsSuccess'),
	setErrorAction: createAction('wallet/setError'),
};
