import { createAction } from '@reduxjs/toolkit';

export const uiActions = {
	setAlertAction: createAction('ui/setAlert'),
	setNavPathAction: createAction('ui/setNavPath'),
	startLoaderAction: createAction('ui/startLoader'),
	endLoaderAction: createAction('ui/endLoader'),
	setThemeAction: createAction('ui/setTheme'),
	toggleSidebarAction: createAction('ui/toggleSidebar'),
	toggleSettingsAction: createAction('ui/toggleSettings'),
	toggleProfileAction: createAction('ui/toggleProfile'),
	toggleNotificationsAction: createAction('ui/toggleNotifications'),
	toggleModalOpenAction: createAction('ui/toggleModalOpen'),
	disablePaymentMethodAction: createAction('ui/disablePaymentMethod'),
};
