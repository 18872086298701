import { styled, Box } from '@mui/material';

export const LoaderBox = styled(Box)(
	({ theme }) => `
    position: absolute;
    display: block;
	  height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1301;
    background: ${theme.colors.alpha.white[70]};
    font-size: calc(16px + 4vw);

    svg {
      width: 55px;
      height: 100px;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      circle {
        fill: ${theme.colors.primary.main};
      }
    }

    @media(max-width: ${theme.breakpoints.values.lg - 1}px) {
      width: 100%;
    }
  `
);
