import { createAction } from '@reduxjs/toolkit';

export const operationsActions = {
	getHelpPageAction: createAction('operations/getHelpPage'),
	getCommissionPlanPageAction: createAction('operations/getCommissionPlanPage'),
	getCommissionsSuccessAction: createAction('operations/getCommissionsSuccess'),
	getCostTypesSuccessAction: createAction('operations/getCostTypesSuccess'),
	getSourceCommissionAction: createAction('operations/getSourceCommission'),
	getTrackingLinksAction: createAction('operations/getTrackingLinks'),
	getTrackingLinksSuccessAction: createAction('operations/getTrackingLinksSuccess'),
	getMacrosAction: createAction('operations/getMacros'),
	getMacrosSuccessAction: createAction('operations/getMacrosSuccess'),
	getManagerDataSuccessAction: createAction('operations/getManagerDataSuccess'),
	getParamsSuccessAction: createAction('operations/getParamsSuccess'),
	getPostbacksAction: createAction('operations/getPostbacks'),
	getPostbacksSuccessAction: createAction('operations/getPostbacksSuccess'),
	getPostbackPageAction: createAction('operations/getPostbackPage'),
	getCommissionPlansAction: createAction('operations/getCommissionPlans'),
	getCommissionPlansSuccessAction: createAction('operations/getCommissionPlansSuccess'),
	getCompliancePageAction: createAction('operations/getCompliancePage'),
	getCompliancePageSuccessAction: createAction('operations/getCompliancePageSuccess'),
	getEventsSuccessAction: createAction('operations/getEventsSuccess'),
	sendContactUsFormAction: createAction('operations/sendContactUsForm'),
	createBenefeciaryAction: createAction('operations/createBenefeciary'),
	createBenefeciarySuccessAction: createAction('operations/createBenefeciarySuccess'),
	signDocumentAction: createAction('operations/signDocument'),
	updateMacrosAction: createAction('operations/updateMacros'),
	updatePostbackAction: createAction('operations/updatePostback'),
	updatePostbackSuccessAction: createAction('operations/updatePostbackSuccess'),
	setFormSentStatusAction: createAction('operations/setFormSentStatus'),
	createPostbackAction: createAction('operations/createPostback'),
	createPostbackSuccessAction: createAction('operations/createPostbackSuccess'),
	clearOperationsDataAction: createAction('operations/clearOperationsData'),
	setErrorAction: createAction('operations/setError'),
};
