import { createAction } from '@reduxjs/toolkit';

export const userActions = {
	getUserDataAction: createAction('user/getUserData'),
	getNotificationsPreferencesAction: createAction('user/getNotificationsPreferences'),
	getNotificationsPreferencesSuccessAction: createAction('user/getNotificationsPreferencesSuccess'),
	setUserInfoAction: createAction('user/setUserInfo'),
	updateUserDataAction: createAction('user/updateUserData'),
	updateProfileAction: createAction('user/updateProfile'),
	createBeneficiaryAction: createAction('user/createBeneficiary'),
	createBeneficiarySuccessAction: createAction('user/createBeneficiarySuccess'),
	updatePreferenceAction: createAction('user/updatePreference'),
	updatePreferencesSuccessAction: createAction('user/updatePreferencesSuccess'),
	changePasswordAction: createAction('user/changePassword'),
	setFormSentStatusAction: createAction('user/setFormSentStatus'),
	setErrorAction: createAction('user/setError'),
	getBeneficiarySuccessAction: createAction('user/getBeneficiarySuccess'),
};
