import { createReducer } from '@reduxjs/toolkit';
import { userActions } from '../actions/user';

const userState = {
	userInfo: {},
	benCreated: false,
	notifications: [],
	isPasswordFormSent: false,
};

const userReducer = createReducer(userState, (builder) => {
	builder
		.addCase(userActions.setUserInfoAction, (state, { payload }) => {
			state.userInfo = { ...state.userInfo, ...payload };
		})
		.addCase(userActions.getNotificationsPreferencesSuccessAction, (state, { payload }) => {
			state.notifications = payload || [];
		})
		.addCase(userActions.updatePreferencesSuccessAction, (state, { payload }) => {
			state.notifications = [...state.notifications].map(el => {
				return el.template_id === payload?.template_id ? {
					...el,
					...payload,
				} : el;
			});
		})
		.addCase(userActions.setFormSentStatusAction, (state, { payload }) => {
			state.isPasswordFormSent = payload;
		})
		.addCase(userActions.getBeneficiarySuccessAction, (state, { payload }) => {
			state.userInfo.beneficiaries = payload?.info?.beneficiaries;
		})
		.addCase(
			userActions.createBeneficiarySuccessAction,
			(state, { payload }) => {
				if (!payload.error) {
					state.userInfo.beneficiaries = [
						...state.userInfo.beneficiaries,
						payload,
					];
					state.benCreated = true;
				}
			}
		)
		.addDefaultCase((state) => state);
});

export default userReducer;
